import React, { useCallback } from 'react';
import { FormQuiz } from '../FormQuiz';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { ErrorBlock } from "@/components/ErrorBlock";
import { useQuery } from "react-query";
import { getAdminQuizById, getQuiz, getQuizLibraryById, getUserQuizById } from "@/features/Quiz/api/getQuizzes";
import { useAuth } from "@/context/AuthContext";
import { Quiz } from '@/features/Quiz';

type Props = {
    isLibrary?: boolean;
    isAdminQuiz?: boolean;
    isUserQuiz?: boolean;
}

export const EditQuiz = ({ isLibrary = false, isAdminQuiz = false, isUserQuiz = false }: Props) => {
    const { user } = useAuth();
    const isSuperAdmin = user?.roles.includes('ROLE_SUPER_ADMIN');

    const { quizId, quizLibraryId, adminQuizId, userQuizId, customerQuizId } = useParams();

    const quizQueryKey = useCallback(() => {
        if (userQuizId) {
            return `getUserQuiz-${userQuizId}`;
        }
        if (adminQuizId) {
            return `getAdminQuiz-${adminQuizId}`;
        }
        if (quizLibraryId) {
            return `getQuizLibrary-${quizLibraryId}`;
        }
        if (customerQuizId) {
            return `getQuizLibrary-${customerQuizId}`;
        }
        return `getQuiz-${quizId}`;
    }, [adminQuizId, quizLibraryId, quizId, customerQuizId]);

    const { data, isLoading, isError, error } = useQuery(
        quizQueryKey(),
        async () => {
            if (quizId || quizLibraryId || adminQuizId || userQuizId) {
                if (userQuizId) {
                    return await getUserQuizById(Number(userQuizId));
                }
                if (adminQuizId) {
                    return await getAdminQuizById(Number(adminQuizId));
                }
                if (quizLibraryId) {
                    return await getQuizLibraryById(Number(quizLibraryId));
                }
                if (customerQuizId) {
                    return await getAdminQuizById(Number(customerQuizId));
                }
                return await getQuiz(Number(quizId));
            }

            return null;
        }, {
            useErrorBoundary: (error: any) => error.response?.status >= 400,
        },
    );

    if (!isSuperAdmin && !isUserQuiz && !isLibrary && !isAdminQuiz) {
        return <Navigate to="/" />
    }

    if (isLoading) return <LoadingSpinner isFullScreen />;
    if (error?.statusCode === 404 || isError) return <ErrorBlock error={'Error'} textOnly={false} />;

    return <FormQuiz quiz={(data as Quiz) ?? null} isLibrary={isLibrary} isUserQuiz={isUserQuiz} isAdminQuiz={isAdminQuiz}
                     isEdit />;
};
